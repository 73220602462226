import {mapState} from "vuex";
import SalesCounter from "../../services/SalesCounter";
import _ from "lodash";


export default {
    props: {
        actionHooks: {
            type: String, default: function () {
                return 'invoice/store';
            }
        }
    },
    data() {
        return {
            isSelectedAll: false,
            isOverlay: false,
            isLoading: false,
            formValid: true,
            orderValid: true,
            showLiveLink: false,
            showItemDeleteConfirm: false,
            showInvoiceConfirm: false,
            ediTableContent: '',
            noDataResult: "Search order by email or order id!",
            orderHeaders: [
                {text: 'Order ID', value: 'orderId'},
                {text: 'Email', value: 'email'},
                {text: 'Live Links', value: 'liveLinks'},
                {text: 'Is Prepaid?', value: 'isPrepayment'},
                {text: 'Amount', value: 'amount', align: 'right'},
                {text: 'Publishable Amount', value: 'publishableAmount', align: 'right'},
                {text: 'Discount', value: 'discount', align: 'right'},
                {text: 'Total Amount', value: 'totalAmount', align: 'right'},
                {text: 'Actions', value: 'actions', sortable: false}
            ],
            orderItems: [],
            headers: [
                {text: 'Order ID', value: 'orderId'},
                {text: 'Email', value: 'email'},
                {text: 'Billing On', value: 'billingOn'},
                {text: 'Live Link', value: 'details'},
                {text: 'Total', value: 'total', align: 'right'},
                {text: 'Actions', value: 'actions', sortable: false}
            ],
            carts: [],
            currentItem: null,
            deleteItem: null,
            invoice: {
                name: '',
                email: '',
                paymentMethod: null,
                amount: this.subTotal,
                totalPublishableAmount: this.totalPublishableAmount,
                discount: 0,
                paymentServiceCharge: 0,
                totalAmount: this.grandTotal,
                description: '',
                isSendMail: true,
                isPrepayment: false,
            },
            order: {
                salesCounterId: '', billingOn: '', liveLink: '', publishableAmount: 0, total: 0
            },
            search: ''
        }
    },
    async mounted() {
        await this.$store.dispatch('method/all', {status: true});
        await this.getInvoiceHandler();
    },
    computed: {
        ...mapState({
            paymentMethods: function (state) {
                const methods = state.method.allPayMethods;
                if(methods.length < 1) return [];
                const method = methods.find((data) => data.normalizedName === 'paddle');
                this.$nextTick(() => {
                    const paymentMethod = this.invoice.paymentMethod;
                    if (method){
                        this.invoice.paymentMethod = paymentMethod ? paymentMethod : method.id;
                    } else {
                        this.invoice.paymentMethod = paymentMethod ? paymentMethod : methods[0].id;
                    }
                })
                return methods;
            }
        }),
        subTotal() {
            return Number.parseFloat(this.carts.reduce((prev, item) => {
                return Number.parseFloat(prev + Number.parseFloat(item.total));
            }, 0)).toFixed(2);
        },
        totalPublishableAmount() {
            return Number.parseFloat(this.carts.reduce((prev, item) => {
                return Number.parseFloat(prev + Number.parseFloat(item.publishableAmount));
            }, 0)).toFixed(2);
        },
        grandTotal() {
            return ((this.subTotal - Number.parseFloat(this.invoice.discount)) + Number.parseFloat(this.invoice.paymentServiceCharge)).toFixed(2);
        },
        getOrderData() {
            return this.orderItems.map((item) => {
                const isInCart = this.carts.find((data) => data.orderId === item.orderId)
                return {
                    ...item, isInCart: !!isInCart
                }
            });
        }
    },
    methods: {
        selectAllInvoiceHandler() {
            this.isSelectedAll = true;
            this.carts = [];
            if (!this.isSelectedAll) {
                this.isSelectedAll = false;
                return;
            }

            if (this.orderItems.length > 0) {
                this.orderItems.forEach((order) => {
                    this.setCartItem(order);
                })
            } else {
                this.isSelectedAll = false;
            }
        },
        handleSearch(term) {
            this.search = term;
            this.noDataResult = "No order found!!"
            if (term) {
                let _term = term.replace(/^\s+|\s+$/gm, '');
                SalesCounter
                    .search({search: _term})
                    .then((response) => {
                        const {orders, invoice} = response.data.data;
                        this.orderItems = orders;
                        this.ediTableContent = invoice && invoice.description ? invoice.description : '';
                        this.invoice.description = invoice && invoice.description ? invoice.description : '';
                        let order = this.carts.find((item) => item.orderId === _term || item.email === _term);
                        if (!order) {
                            this.carts = [];
                        }
                        if (orders.length === 0) {
                            this.noDataResult = "No order found!!"
                            this.setSnackBar({text: "Unpaid order not found!", type: 'info'});
                        }
                    })
                    .catch((errors) => {
                        this.noDataResult = "No order found!!"
                        console.log(errors);
                    })
            }
        },
        addToCartHandler(item) {
            this.setCartItem(item);
        },
        liveLinkHandler(item) {
            this.showLiveLink = true;
            this.currentItem = item;
        },
        closePopupHandler() {
            this.showLiveLink = false;
            this.currentItem = null;
        },
        confirmToDeleteItem(item) {
            this.isSelectedAll = false;
            this.showItemDeleteConfirm = true;
            this.deleteItem = item;
        },
        getAmountWithSymbol(amount) {
            const _amount = Number.parseFloat(amount).toFixed(2);
            return `$${_amount}`;
        },
        confirmDelete() {
            const id = this.deleteItem.id;
            const index = this.carts.findIndex((item) => item.id === id);

            if (index > -1) {
                this.carts.splice(index, 1);
            }

            this.showItemDeleteConfirm = false;
            this.deleteItem = null;
        },
        resetForm() {
            this.$refs.cartFormRef.reset();
            const method = this.paymentMethods.find((data) => data.normalizedName === 'paddle');
            this.$nextTick(() => {
                this.orderItems = [];
                this.search = '';
                this.invoice = {
                    name: '',
                    email: '',
                    paymentMethod: method.id,
                    amount: 0,
                    totalPublishableAmount: 0,
                    paymentServiceCharge: 0,
                    discount: 0,
                    totalAmount: 0,
                    description: '',
                    isSendMail: true
                }
            })
        },
        async onConfirmHandler(confirmed = false) {
            if (!this.$refs.cartFormRef.validate()) return;

            if (!confirmed) {
                this.showInvoiceConfirm = true;
                return;
            }
            this.showInvoiceConfirm = false;

            let email = this.carts[0].email;

            this.$store.dispatch(this.actionHooks, {
                ...this.invoice,
                email,
                amount: this.subTotal,
                totalAmount: this.grandTotal,
                totalPublishableAmount: this.totalPublishableAmount,
                carts: this.carts
            })
                .then((response) => {
                    this.setSnackBar({text: response.data.message, type: 'info'});
                    if (this.actionHooks !== 'invoice/update') {
                        this.carts = [];
                        this.resetForm();
                    }
                })
                .catch((error) => {
                    this.setSnackBar({text: "Data Validation Error", type: 'error'});
                })

            if (this.actionHooks === 'invoice/update') {
                await this.$router.push({name:"Invoices", path: "/invoices", query: this.$route.query});
            }
        },
        setSnackBar({text, type}) {
            this.$store.commit('SET_SNACKBAR', {text, type});
        },
        handleEditableInput(e) {
            this.invoice.description = e.target.innerHTML;
        },
        getAutoInvoiceDescription() {
            this.invoice.description = '';
            this.ediTableContent = '';
            this.isOverlay = true;

            if (!this.search) {
                this.$store.commit('SET_SNACKBAR', {text: 'Please select a email first!', type: 'error'});
                this.isOverlay = false;
                return;
            }

            this.$store.dispatch('invoice/getDescriptionByEmail', {email: this.search})
                .then(({data: {data}}) => {
                    if (data.item.description) {
                        this.invoice.description = data.item.description;
                        this.ediTableContent = data.item.description;
                    }
                    this.isOverlay = false;
                })
                .catch((errors) => {
                    this.isOverlay = false;
                })
        },
        setCartItem(item) {
            const {
                id,
                totalAmount,
                billingType,
                sales_counter_details: details,
                name,
                email,
                orderId,
                publishableAmount,
                discount,
                isPrepayment
            } = item;

            this.invoice.name = name;
            this.invoice.email = email;
            this.invoice.isPrepayment = isPrepayment;

            let sites = [];
            let links = "";

            const index = this.carts.findIndex((data) => data.id === id);

            if (index > -1) return;

            details.forEach((data) => {
                const {site, liveLink} = data;
                sites.push(site.site_url);
                if (links) {
                    links = links + ', ' + liveLink;
                } else {
                    links = liveLink;
                }
            })

            let sitesSet = _.uniq(sites);
            let cart = {
                id,
                orderId,
                email,
                salesCounterId: id,
                billingOn: billingType + ' ' + _.join(sitesSet, ', '),
                details: item,
                liveLink: links,
                total: totalAmount,
                publishableAmount: Number.parseFloat(publishableAmount) - Number.parseFloat(discount)
            };

            if (this.invoice.id) {
                cart.invoiceId = this.invoice.id;
            }

            this.carts.push(cart);
        },
        async getInvoiceHandler() {
            if (!this.$route.params.id) return;
            this.isOverlay = true;
            try {
                let {data: {data: {item}}} = await this.$store.dispatch('invoice/get', {id: this.$route.params.id})
                const {
                    id,
                    name,
                    email,
                    amount,
                    discount,
                    totalAmount,
                    paymentMethod,
                    invoice_detail: invoiceDetails,
                    description,
                    totalPublishableAmount,
                    paymentServiceCharge,
                    isPrepayment
                } = item;

                this.ediTableContent = description;
                this.invoice = {
                    id,
                    name,
                    email,
                    amount,
                    discount,
                    totalAmount,
                    paymentMethod,
                    description,
                    paymentServiceCharge,
                    totalPublishableAmount,
                    isSendMail: true,
                    isPrepayment
                };
                this.carts = invoiceDetails.map((data) => {
                    const {
                        sale_counter: saleCounter,
                        salesCounterId,
                        billingOn,
                        liveLink,
                        total,
                        publishableAmount,
                        invoiceId,
                        id
                    } = data;
                    const {sales_counter_details: details, orderId, email} = saleCounter;

                    return {
                        id,
                        orderId,
                        email,
                        invoiceId,
                        salesCounterId,
                        billingOn,
                        details: {...saleCounter, details},
                        liveLink,
                        publishableAmount,
                        total
                    }
                })
                this.isOverlay = false;

            } catch (error) {
                this.isOverlay = false;
                this.setSnackBar({text: "Data Not Found!", type: 'error'});
                await this.$router.push('/invoices');
            }
        }
    }
}