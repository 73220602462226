<template>
<g-invoice/>
</template>

<script>

import GInvoice from "../../components/GInvoice";
export default {
  name: "Invoice",
  components: {GInvoice},
  metaInfo: {
    title: 'shantoitfirm.com',
    titleTemplate: 'Admin Panel - Invoice | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  }
}
</script>